.LandingPage__body{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
    /* background: grey; */
}

.LandingPage__p{
    max-width: 50%;
}

.LandingPage__image {
    width: 40%;
    border-radius: 50%;

}

.form {
    margin: 20px;
    width: 50%;
    position: relative;
    height: 50px;
    /* background: blue; */
    /* overflow:hidden; */

}

/* <button type="submit">Start</button> */
.form input {
    width: 100%;
    height: 100%;
    color: grey;
    padding-top: 10px;
    font-size: 20px;
    border: none;
    text-align: center;
    outline: none;

}
.form label {
    position: absolute;
    bottom:0px;
    left:0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid black;
}

.form label::after {
    content: '';
    position: absolute;
    left:0px;
    bottom:-1px;
    height: 100%;
    width:100%;
    border-bottom: 3px solid skyblue;
    transform: translateY(-3%);
    transition: transform 0.3s ease;
    
}

.content-name {
    position: absolute;
    bottom:5px;
    left:0px;
    right:0px;
    transition:  all 0.3s ease;
}

.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
    transform: translateY(-150%);
    font-size:14px;
    color:skyblue;
}

.form input:focus + .label-name::after,
.form input:valid + .label-name::after {
    transform: translateX(0%);
}


.btn {
    margin: 20px;
    width: 50%;
    border: none;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #60a3bc;
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
}

.btn:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
transition: all 0.4s ease 0s;
    }