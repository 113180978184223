.Dogs__body{
    /* width: 40%; */
    flex-grow:4;
    /* border: solid 1px black; */

}
.dog__img {
    width: 250px;
    height:250px ;
    border-radius: 0px 37px 0px 37px;
}