.AdoptPage{
    height: 100%;
    text-align: center;
    font-family: sans-serif;
    max-width: 900px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.AdoptPage__section{
    display: flex;
    /* justify-content: space-around; */
    /* align-items: center; */
    /* flex-direction: row; */
    align-items: stretch;
    flex-wrap: wrap;
}

li {
    list-style-type: none;
}

/* .AdoptPage__section :first-child{
    width: 30%;
    border: solid 1px black;
}
.AdoptPage__section :nth-child(2){
    width: 30%;
    border: solid 1px black;

}
.AdoptPage__section :last-child{
    width: 30%;
    border: solid 1px black;

} */



.adopt__btn {
    margin: 20px;
    /* width: 50%; */
    border: none;
background: #404040;
color: #ffffff !important;
font-weight: 100;
padding: 15px;
text-transform: uppercase;
border-radius: 6px;
display: inline-block;
transition: all 0.3s ease 0s;
}

.adopt__btn:hover {
    color: #404040 !important;
    font-weight: 700 !important;
    letter-spacing: 3px;
    background: none;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.3s ease 0s;
    }

    .morePets__btn {
        color: #20bf6b !important;
        text-transform: uppercase;
        background: #ffffff;
        padding: 10px;
        border: 4px solid #20bf6b !important;
        border-radius: 6px;
        display: inline-block;
        transition: all 0.3s ease 0s;
        width: 50%;
        border: none;
        outline: none;

        }

        .morePets__btn:hover {
            color: #494949 !important;
            border-radius: 60px;
            border-color: #494949 !important;
            transition: all 0.3s ease 0s;
            }

            .pets__ul {
                padding: 0px;
            }
            .goBack__btn {
                color: lightcoral !important;
                text-transform: uppercase;
                background: #ffffff;
                padding: 10px;
                border: 4px solid lightcoral !important;
                border-radius: 6px;
                display: inline-block;
                transition: all 0.3s ease 0s;
                width: 50%;
                border: none;
                outline: none;
        
                }
        
                .goBack__btn:hover {
                    color: #494949 !important;
                    border-radius: 50px;
                    border-color: #494949 !important;
                    transition: all 0.3s ease 0s;
                    }